// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  stripe_token: 'STRIPE_TOKEN',
  paypal_token: 'PAYPAL_TOKEN',
  epayco_public_key: 'c5402cbaaf22aaea9bd8039476535221',

  urlFront: 'https://artesaniaslumar.com/',
  

  apiBaseUrl: 'https://artesaniaslumar.com/api',

  //RUTA SECCION
  seccionUrl: 'https://artesaniaslumar.com/api/seccion/',

  //RUTA CATEGORIA
  categoriaUrl: 'https://artesaniaslumar.com/api/categoria/',

  //RUTA SUBCATEGORIA
  subcategoriaUrl: 'https://artesaniaslumar.com/api/subcategoria/',

  //RUTA PRODUCTO
  productoURL: 'https://artesaniaslumar.com/api/producto/',

  //RUTA USUARIO
  usuarioURL: 'https://artesaniaslumar.com/api/usuario/',
  //RUTA IMAGEN USUARIO
  usuarioIMG_URL: 'https://artesaniaslumar.com/api/uploads/usuarios/',
  //RUTA ROLES
  rol_URL: 'https://artesaniaslumar.com/api/rol/',

  //RUTA CUPON
  cupon_URL: 'https://artesaniaslumar.com/api/cupon/',

  //RUTA TIPO DE DESCUENTO
  tipo_descuento_URL: 'https://artesaniaslumar.com/api/tipo-descuento/',

  //RUTA COMENTARIOS
  comentario_URL: 'https://artesaniaslumar.com/api/comentarios/',

  //RUTA UBICACIÓN
  ubicacion_URL: 'https://artesaniaslumar.com/api/ubicacion/',

  //RUTA CONTACTO
  contacto_URL: 'https://artesaniaslumar.com/api/contacto/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
