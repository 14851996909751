<div class="product-container">
  <div *ngIf="!loader">
    <div class="img-wrapper">
      <div class="lable-block">
        <span class="lable3" *ngIf="product.new">new</span>
        <span class="lable4" *ngIf="product.sale">on sale</span>
      </div>
      <div class="front">
        <a (click)="sendProducto(product.prod_id)">
          <img
            [src]="product.imagenes && product.imagenes.length > 0 ? urlApi + product.imagenes[0].imagen_url : 'path/to/default/image.jpg'"
            [lazyLoad]="ImageSrc ? ImageSrc : product.imagenes[0].src" class="img-fluid lazy-loading"
            alt="{{product.prod_nombre}}" />
        </a>
      </div>
      <div class="back" *ngIf="onHowerChangeImage">
        <a (click)="sendProducto(product.prod_id)">
          <img
            [src]="product.imagenes && product.imagenes.length > 0 ? urlApi + product.imagenes[0].imagen_url : 'path/to/default/image.jpg'"
            class="img-fluid lazy-loading" alt="{{ product.prod_nombre }}">
        </a>
      </div>
      <div class="cart-info cart-wrap cart-box">
        <a href="javascript:void(0)" title="Añadir a Carrito" (click)="CartModal.openModal(product)" *ngIf="cartModal">
          <i class="ti-shopping-cart"></i>
        </a>
        <a href="javascript:void(0)" title="Añadir a Carrito" (click)="addToCart(product)" *ngIf="!cartModal">
          <i class="ti-shopping-cart"></i>
        </a>
        <a href="javascript:void(0)" title="Comprar Producto" (click)="buyNow(product)">
          <i class="ti-money" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" title="Detalles del Producto" (click)="QuickView.openModal()">
          <i class="ti-more" aria-hidden="true"></i>
        </a>
      </div>
    </div>
    <div class="product-detail">
      <div>
        <div class="rating-container">
          <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
        </div>

        <a (click)="sendProducto(product.prod_id)">
          <h6>{{ product?.prod_nombre | titlecase }}</h6>
        </a>
        <!-- <p>{{ product?.description }}</p> -->
        <h4>{{ product.prod_precio | currency }} <del>{{ product.prod_precio_oferta | currency }}</del></h4>
      </div>
    </div>
  </div>
</div>

<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product"></app-quick-view>
<app-cart-modal #cartModal [product]="product" *ngIf="cartModal"></app-cart-modal>