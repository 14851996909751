import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer-lumar',
  templateUrl: './footer-lumar.component.html',
  styleUrls: ['./footer-lumar.component.scss']
})
export class FooterLumarComponent implements OnInit {

  @Input() class: string = 'footer-light' // Default class 
  @Input() themeLogo: string = 'assets/images/icon/logo-lumar-footer.png' // Default Logo
  @Input() newsletter: boolean = true; // Default True

  public today: number = Date.now();

  constructor() { }

  ngOnInit(): void {
  }

}
