<div class="container-redes">
    <input type="checkbox" id="btn-desplegar">
    <div class="redes">
        <a href="https://www.facebook.com/ArtesaniasLumar?mibextid=ZbWKwL" class="fa fa-facebook" target="_blank"
            rel="noopener noreferrer"></a>
        <a href="https://www.instagram.com/artesaniaslumar/" class="fa fa-instagram" target="_blank"
            rel="noopener noreferrer"></a>
        <a href="https://www.tiktok.com/@artesaniaslumar?_t=8pGBz9wVWcA&_r=1" class="fab fa-tiktok" target="_blank"
            rel="noopener noreferrer"></a>
        <a href="https://wa.me/+573178804915/?text=Hola, quisiera contactar para" class="fa fa-whatsapp" target="_blank"
            rel="noopener noreferrer"></a>
        <a href="mailto:artesaniasputumayocolombia@gmail.com" class="fa fa-envelope" target="_blank"
            rel="noopener noreferrer"></a>
    </div>
    <div class="btn-mas">
        <label for="btn-desplegar" class="fa fa-plus" id="signo-mas"></label>
    </div>
</div>