<!-- Add to cart modal popup start-->
<ng-template #cartModal let-modal>
    <div class="modal-body">
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-12">
                    <div class="modal-bg addtocart">
                        <button type="button" id="close-cart-modal" class="close"
                            (click)="modal.dismiss('Cross click')">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <div class="media">
                            <a [routerLink]="['/shop/product/left/sidebar/']">
                                <img [src]="product.imagenes && product.imagenes.length > 0 ? urlApi + product.imagenes[0].imagen_url : 'path/to/default/image.jpg'"
                                    class="img-fluid blur-up lazyload bg-img" alt="Product Image">
                            </a>
                            <div class="media-body align-self-center text-center">
                                <a [routerLink]="['/shop/product/left/sidebar/']">
                                    <h6>
                                        <i class="fa fa-check"></i>
                                        <span>{{ product.prod_nombre | titlecase }}</span>
                                        <span> agregado exitosamente a su carrito</span>
                                    </h6>
                                </a>
                                <div class="buttons">
                                    <a [routerLink]="['/shop/cart']" class="view-cart btn btn-solid">Tu carrito</a>
                                    <a (click)="continueShopping()"
                                        class="continue btn btn-solid">Continuar comprando</a>
                                </div>

                                <div class="upsell_payment">
                                    <img src="assets/images/payment_cart.webp" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>

                        <div class="product-section">
                            <div class="col-12 product-upsell text-center">
                                <h4>Clientes que compraron este artículo también.</h4>
                            </div>
                            <div class="row" id="upsell_product">
                                <ng-container *ngFor="let product of products | slice:0:4">
                                    <div class="product-box col-sm-3 col-6">
                                        <div class="img-wrapper">
                                            <div class="front">
                                                <a
                                                    [routerLink]="['/shop/product/left/sidebar/']">
                                                    <img [src]="product.imagenes && product.imagenes.length > 0 ? urlApi + product.imagenes[0].imagen_url : 'path/to/default/image.jpg'"
                                                        class="img-fluid blur-up lazyload bg-img" alt="Product Image">
                                                </a>
                                            </div>
                                            <div class="product-detail">
                                                <h6 class="mt-0">
                                                    <a
                                                        [routerLink]="['/shop/product/left/sidebar/']"><span>{{
                                                            product.prod_nombre | titlecase }}</span></a>
                                                </h6>
                                                <h4>{{ product.prod_precio | currency }} <del>{{
                                                        product.prod_precio_oferta | currency }}</del></h4>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!-- Add to cart modal popup end-->