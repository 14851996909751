import { Component, OnInit } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {

  public products: Product[] = [];
  public collapse: boolean = true;

  constructor(public productService: ProductService) {
    this.productService.getProducts.subscribe(product => this.products = product);
  }

  ngOnInit(): void {
  }

  get filterbyCategory() {
    const categories = [
      { name: 'Regalos', link: '/shop/categoria/regalos' },
      { name: 'Decorativas', link: '/shop/categoria/decorativas' },
      { name: 'Hogar', link: '/shop/categoria/hogar' },
      { name: 'Oficina', link: '/shop/categoria/doficina' },
      { name: 'Joyeria', link: '/shop/categoria/joyeria' },
      { name: 'Accesorios para ropa', link: '/shop/categoria/accesorios-ropa' },
      { name: 'Ropa para hombre', link: '/shop/categoria/ropa-hombre' },
      { name: 'Ropa para mujer', link: '/shop/categoria/ropa-mujer' },
      { name: 'Mochilas', link: '/shop/categoria/mochilas' },
      { name: 'Billetera', link: '/shop/categoria/billetera' },
    ];
    return categories;
  }

}
