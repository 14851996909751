import { ViewportScroller } from '@angular/common';
import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-redes-sociales-floating',
  templateUrl: './redes-sociales-floating.component.html',
  styleUrl: './redes-sociales-floating.component.scss'
})
export class RedesSocialesFloatingComponent {

  public show: boolean = true;
  
  url_whatsapp: string = "https://api.whatsapp.com/send?phone=3178804915"

  constructor(private viewScroller: ViewportScroller) { }

  ngOnInit(): void {
  }

}
